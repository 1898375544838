@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
  }

  body {
    @apply bg-[#1C2826] text-[#DAEFB3];
  }

  /* Custom container styles */
  .container {
    @apply mx-auto px-4;
    max-width: 1400px;
  }
}

/* Custom animations */
@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}